html,
body,
app-root {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: #f1f5fe;
  font-family: 'Nunito Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--primary-color);
  font-weight: 700;
}

label {
  color: var(--text-primary-color);
  font-size: var(--text-primary-size);
  font-weight: 700;
}

p,
small {
  color: #404b5e;
}

.container-tp2,
.container-tp2-gray {
  width: 90%;
  height: auto;
  margin: 0;
  margin: auto;
  padding: 2.5rem 0;
  background-color: #f1f5fe;
  overflow: auto;
}

.inputRequired {
  color: red;
}

.container-tp2-gray {
  background-color: #eff3f8 !important;
}

.p-dialog-content {
  height: 100%;
}

p-dialog[ng-reflect-visible='false'] {
  .p-dialog-mask {
    display: none;
  }
}

p-dropdown {
  display: block;
}

.p-dropdown {
  min-width: 100%;
  max-width: 100%;
}

.p-multiselect {
  min-width: 100%;
  width: 100%;
}

.p-dropdown-panel {
  max-width: 15rem;
}

p-inputnumber {
  display: block;
}

input {
  width: 100%;
}

.form-error {
  color: red;
  font-weight: bold;
  display: block;
}

.full-width {
  width: 100%;
}

.p-inputswitch {
  vertical-align: middle;
}

textarea {
  resize: none;
}

.p-selectbutton .p-button.p-highlight {
  background-color: #11c9d3;
}

.p-selectbutton .p-button.p-highlight:hover {
  background-color: #11c9d3;
}

.p-button.p-button-icon-only {
  padding: 0.5rem 1rem;
}

.p-tooltip {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}
.p-tooltip.type-of-component {
  max-width: 16.5rem;
}
ui-tooltip {
  pointer-events: none;
}

.p-column-filter-overlay {
  background: #ffffff;
  color: #495057;
  border: 0 none;
  border-radius: 3px;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
  min-width: 12.5rem;
  padding: 5px;
}

.button_action {
  color: #ffffff !important;
}
.p-inputAutocompleteButton,
.dictionary {
  display: flex;
  align-items: center;
  position: relative;
}

.p-inputAutocompleteButton {
  display: flex;
  align-items: stretch;
}

.p-toast.p-component.p-toast-top-center {
  z-index: 99999;
}

.p-grid {
  margin: 0;
}

.scroll-conciliation {
  overflow: auto;
  padding-right: 0.5rem;
}
.scroll-conciliation::-webkit-scrollbar {
  width: 0.4rem;
}

.scroll-conciliation::-webkit-scrollbar-track {
  background: #ececec;
}

.scroll-conciliation::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

.conciliation *::-webkit-scrollbar {
  width: 0.4rem;
}

.conciliation *::-webkit-scrollbar-track {
  background: #ececec;
}

.conciliation *::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

.oldValue {
  font-weight: 700;
  color: #ff5252;
  text-decoration: line-through;
}
.newValue {
  font-weight: 700;
  color: #66bb6a;
}

.emConciliated {
  font-weight: 700;
  color: #66bb6a;
}
.emPendingConciliated {
  font-weight: 700;
  color: var(--primary-color);
}

.emPendingInstance {
  font-weight: 700;
  color: #f59e0b;
}
.container-tp3 {
  width: 90%;
  margin: 0;
  margin: auto;
  padding: 1rem;
  background-color: #f1f5fe;
  padding-bottom: 2rem;
}

.summary span {
  font-weight: 600;
}

.summary .p-col-12 {
  padding-left: 0;
}

::ng-deep {
  .p-editor-container
    .p-editor-toolbar.ql-snow
    .ql-picker.ql-expanded
    .ql-picker-options {
    background: var(--primary-color) !important;
  }
}

//Estilos para las nuevas cards en tp2
.tp2Card {
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 2rem 3rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
}

.grid-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(20rem, 100%), 1fr));
  gap: 1rem;
}

//buttons

.btn {
  background-color: var(--button-bg-color);
  border: 1px solid var(--button-bg-color);
  border-radius: 0.25rem;
  color: var(--button-text-color);
  font-size: 14px;
  width: max-content;
  box-shadow: none;

  &:hover,
  .p-button:enabled:hover {
    background-color: var(--button-bg-hover-color);
    color: var(--button-text-color);
    border: 1px solid var(--button-bg-color);
  }

  .p-button:focus,
  &:focus {
    color: var(--button-text-color);
    box-shadow: none;
  }

  .p-button:active,
  &:active,
  &:enabled:active {
    background-color: var(--button-bg-hover-color);
    border: 1px solid var(--button-bg-color);
    box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
    color: var(--button-text-color);
  }

  &:enabled:hover {
    background-color: var(--button-bg-hover-color);
    color: var(--button-text-color);
  }

  &.p-button:enabled:hover {
    background-color: var(--button-bg-hover-color);
  }

  &:disabled {
    border: 1px solid #cacaca;
    color: #878787;
    background: #cacaca;
    pointer-events: none;

    & .pi {
      color: #878787;
    }
  }
}

.btn-primary {
  --button-bg-color: var(--primary-color);
  --button-text-color: #fff;

  &:hover {
    --button-bg-hover-color: #028ead;
  }

  &:active {
    --button-bg-color: var(--primary-color);
  }

  &:disabled {
    --button-bg-color: #b3b3b3;
  }
}

.btn-danger {
  --button-bg-color: #e27a7a;
  --button-text-color: #fff;

  &:hover {
    --button-bg-hover-color: #e98a8a;
    --button-bg-color: #e27a7a;
  }

  &:active {
    --button-bg-color: #e27a7a;
  }

  &:disabled {
    --button-bg-color: #b3b3b3;
  }
}

.btn-info {
  --button-bg-color: #11c9d3;
  --button-text-color: #076368;
  --button-bg-hover-color: #0edbe6;
  &:hover {
    --button-bg-hover-color: #0edbe6;
    --button-text-color: #076368;
  }
}

.step-subtitle {
  font-size: var(--text-primary-size);
  color: var(--text-primary-color);
}

.step-subtitle span:first-child {
  font-weight: 700;
  color: var(--primary-color);
}

//dialog
.custom-dialog-header .p-dialog .p-dialog-content {
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.custom-dialog-header .p-dialog .p-dialog-header {
  border-radius: 0.5rem 0.5rem 0 0;
}

.custom-dialog-header .p-dialog .p-dialog-header .p-dialog-title {
  color: var(--text-label-color);
  font-size: var(--text-subtitle-size);
  font-weight: var(--text-bold);
}

.custom-dialog-header .p-dialog .p-dialog-header .p-dialog-header-icon {
  color: #347994;
  border-radius: 50%;
}

@media screen and (max-width: 768px) {
  .tp2Card {
    padding: 1rem 1.5rem;
  }
}

@media screen and (max-width: 576px) {
  .container-tp2,
  .container-tp2-gray {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .p-accordion .p-accordion-content {
    border: none;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 8px 8px;
  }
}

.grid-ediary-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(22rem, 1fr));
  gap: 1rem;
}

/**---modal firma--**/

.signature-modal,
.modal-container {
  width: max-content;
  &.p-dialog {
    border-radius: 1rem;
    overflow: hidden;
  }

  &.p-dialog .p-dialog-content {
    padding: 2rem 1rem;
  }
}

.modal-container {
  &.p-dialog .p-dialog-content {
    padding: 1rem;
    padding-top: 0;
  }
}

.modal-rounded {
  &.p-dialog {
    border-radius: 0.5rem;
    overflow: hidden;
  }
}

.p-toast .p-toast-message.p-toast-message-info .p-toast-message-icon,
.p-toast .p-toast-message.p-toast-message-info .p-toast-icon-close {
  color: var(--info-test-color);
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  font-weight: var(--text-bold);
  color: var(--text-primary-label);
}

.p-toast .p-toast-message.p-toast-message-info {
  border-radius: 0.5rem;
  border: 1px solid var(--info-border-color);
  background: var(--info-bg-color);
}

//tooltip
.tp2-tooltip {
  .p-tooltip-text {
    border-radius: 4px;
    background: #29313d;
    color: #ffffff;
    font-size: 0.875rem;
  }
}

.tp2-tooltip-top {
  .p-tooltip-arrow {
    border-top-color: #29313d !important;
  }
}

.tp2-tooltip-right {
  .p-tooltip-arrow {
    border-right-color: #29313d !important;
  }
}

.tp2-tooltip-bottom {
  .p-tooltip-arrow {
    border-bottom-color: #29313d !important;
  }
}

.tp2-tooltip-left {
  .p-tooltip-arrow {
    border-left-color: #29313d !important;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.required::after {
  content: '*';
  color: var(--text-danger-color);
}

.p-skeleton {
  background-color: #dee2e6;
}
.p-skeleton::after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
}

.h-screen {
  min-height: 100vh;
  height: 100vh;
}

.p-datatable-scrollable .p-datatable-thead {
  z-index: 0 !important;
}
